<script setup>
import { computed } from 'vue';
import { format } from 'date-fns';

const props = defineProps({
  record: {
    type: Object,
    default: () => ({}),
  },
  patient: {
    type: Object,
    default: () => ({}),
  },
  doctor: {
    type: Object,
    default: () => ({}),
  },
});

const items = computed(() => {
  return props.record.items?.map(item => {
    return {
      ...item,
      formattedSig: `${item.dosageSig || ''} ${item.frequency || ''}`,
    };
  });
});
const date = computed(() => props.record?.createdAt && format(new Date(props.record.createdAt), 'MMM dd, yyyy'));
const notes = computed(() => props.record?.notes);

// const patient = computed(() => props.record?.patientDetails);
const doctor = computed(() => props.doctor);
// const pharmacy = computed(() => props.record?.pharmacyDetails);

</script>

<template>
<div class="flex flex-col gap-4 h-[70vh]">
  <div class="flex flex-col items-center justify-center">
    <h1 class="font-medium text-xl">Dr. {{doctor?.formattedName}}</h1>
    <h2 class="font-medium text-md">{{doctor?.formattedSpecializations}}</h2>
  </div>
  <div class="border border-1">
   <table class="table table-sm">
    <tbody>
      <tr>
        <td>Name: {{patient?.formattedName || '-'}}</td>
        <td>Date: {{record?.formattedCreatedAt || '-'}}</td>
      </tr>
      <tr>
        <td>Sex: {{patient?.formattedSex || '-'}}</td>
        <td>Age: {{patient?.formattedAge || '-'}}</td>
      </tr>
      <tr>
        <td colspan="2">
          Address: {{patient?.formattedAddress}}
        </td>
      </tr>
    </tbody>
   </table>
  </div>
  <div class="flex flex-col gap-4">
    <div class="flex justify-between items-center">
      <span class="text-[40px]">℞</span>
      <span>Date: {{ date }}</span>
    </div>
    <div class="flex flex-col gap-4">
      <div v-for="(medicine, index) in items" :key="index">
        <div class="grid grid-cols-12">
          <div class="col-span-12 lg:col-span-4">
            <span class="font-bold">Generic Name:</span>
          </div>
          <div class="col-span-12 lg:col-span-8">
            {{ medicine.genericName || '-' }}
          </div>
          <div class="col-span-12 lg:col-span-4">
            Brand Name:
          </div>
          <div class="col-span-12 lg:col-span-8">
            {{ medicine.brandName || '-' }}
          </div>
          <div class="col-span-12 lg:col-span-4">
            Dosage Form:
          </div>
          <div class="col-span-12 lg:col-span-8">
            {{ medicine.formulationClassification || '-' }}
          </div>
          <div class="col-span-12 lg:col-span-4">
            Strength:
          </div>
          <div class="col-span-12 lg:col-span-8">
            {{ medicine.formulation || '-' }}
          </div>
          <div class="col-span-12 lg:col-span-4">
            Sig:
          </div>
          <div class="col-span-12 lg:col-span-8">
            {{ medicine.dosageSig || '-' }}
          </div>
          <div class="col-span-12 lg:col-span-4">
            Duration:
          </div>
          <div class="col-span-12 lg:col-span-8">
            {{ medicine.frequency || '-' }}
          </div>
          <div class="col-span-12 lg:col-span-4">
            Quantity:
          </div>
          <div class="col-span-12 lg:col-span-8">
            {{ medicine.dispense || '-' }}
          </div>
          <div class="col-span-12 lg:col-span-4">
            Refills:
          </div>
          <div class="col-span-12 lg:col-span-8">
            {{ medicine.frequencyReminderInterval || '-' }}
          </div>
        </div>
      </div>
      <p v-if="notes">Notes: {{notes}}</p>
    </div>
    <div class="w-full flex justify-end">
      <table class="w-full  lg:w-8/12">
        <tr>
          <td align="center">
            <span class="font-bold">Pharmacist Name & Signature:</span>
          </td>
          <td align="right">
            <span class="font-bold">{{ doctor?.formattedName || '-' }}</span>
          </td>
        </tr>
        <tr>
          <td align="center">Pharmacist Registration Number:</td>
          <td align="right">{{ doctor?.doc_PRCLicenseNo || '-' }}</td>
        </tr>
      </table>
    </div>
  </div>
</div>
</template>

<!-- <style scoped>
table, tr, td {
  border-collapse: collapse;
  border: 1px solid lightgrey;
}
</style> -->

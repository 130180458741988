<script setup>
import { usePrescription } from './composables';
import { computed, ref } from 'vue';
import { pickBy, identity, omit } from 'lodash';
import { ROUTE_OPTS, FREQUENCY_OPTS } from './constants';
import MedicineCatalog from '@/components/commons/MedicineCatalog.vue';

const emit = defineEmits(['submit', 'success', 'error']);

defineExpose({
  submit,
  setForm,
  resetForm,
});

const props = defineProps({
  showLabel: {
    type: Boolean,
    default: false,
  },
  encounter: {
    type: String,
    default: '',
  },
  patient: {
    type: String,
    default: '',
  },
  hideActions: {
    type: Boolean,
    default: false,
  },
  modalOpen: {
    type: Boolean,
    default: false,
  },
});

const model = ref([
  {
    genericName: '',
    brandName: '',
    formulation: '',
    dispense: '',
    frequency: '',
    dosageSig: '',
    note: '',
  }
]);

const { submit: submitPrescription } = usePrescription();

const record = ref({});
const recordId = computed(() => record.value.id);
const isEditing = computed(() => !!recordId.value);
const loading = ref(false);

async function submit () {
  try {
    loading.value = true;
    
    const payload = {
      items: model.value.map(item => {
        return pickBy(omit(item, ['formulations'], identity));
      }),
    };

    // Update
    if (isEditing.value) {
      payload.id = recordId.value;
    }

    // Create
    await submitPrescription(payload, { patient: props.patient, encounter: props.encounter });

    resetForm();
    emit('submit');
    emit('success');
  } catch (e) {
    console.error('Something went wrong. Try again later.');
    emit('error', e);
  } finally {
    loading.value = false;
  }
}

function setForm (data) {
  if (!data) return;
  record.value = data;
  model.value = data.items.map(item => {
    const newItem = {
      ...item,
      startedAt: item.startedAt ? new Date(item.startedAt).toISOString().split('T')[0] : undefined,
      endedAt: item.endedAt ? new Date(item.endedAt).toISOString().split('T')[0] : undefined,
    };
    return pickBy(newItem, identity);
  });
}

function resetForm () {
  record.value = {};
  model.value = [
    {
      genericName: '',
      brandName: '',
      formulation: '',
      dispense: '',
      frequency: '',
      dosageSig: '',
      note: '',
    }
  ];
}

function addMedicine () {
  model.value.push({
    genericName: '',
    brandName: '',
    formulation: '',
    dispense: '',
    frequency: '',
    dosageSig: '',
    note: '',
  });
}

function removeMedicine (index) {
  model.value.splice(index, 1);
}

const medicineCatalogDialog = ref(false);
const activeMedicineIndex = ref(null);

function openMedicineCatalog (index) {
  activeMedicineIndex.value = index;
  medicineCatalogDialog.value = true;
}

function onSelectMedicine (value) {
  console.warn('onSelectMedicine', value);
  const index = activeMedicineIndex.value;
  if (index === null) return;

  const updatedMedicine = {
    ...model.value[index],
    genericName: value.genericName || value.medicine.genericName,
    brandName: value.brandName,
    formulationClassification: value.formulationClassification,
    dispense: value.dispense,
    frequency: value.frequency,
    dosageSig: value.dosageSig,
    note: value.note,
  };

  if (value.formulations?.length) {
    updatedMedicine.formulations = value.formulations;
    updatedMedicine.formulation = value.formulations[0].formulation;
  } else {
    updatedMedicine.formulation = value.formulation;
  }

  model.value[index] = updatedMedicine;

  // Force reactivity update
  model.value = [...model.value];

  medicineCatalogDialog.value = false;
  activeMedicineIndex.value = null;
}
</script>

<template>
  <form
    class="flex flex-col gap-8 min-h-[500px]"
    @submit.prevent="submit"
  >
    <div class="flex flex-col gap-4">
      <template v-for="(med, index) in model">
        <div class="flex justify-between items-center mb-4">
          <span class="text-primary font-bold text-md">Medicine {{ index + 1 }}</span>
          <div class="flex gap-2">
            <button
              type="button"
              class="btn btn-sm btn-circle"
              @click="removeMedicine(index)"
            >
              <i class="las la-trash text-2xl text-error" />
            </button>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4 p-4 rounded-xl bg-neutral-50 border border-gray-300 shadow-sm">
          <label class="form-control col-span-2">
            <div class="label">
              <span class="label-text font-medium">Generic Name <span class="text-error">*</span></span>
            </div>
            <div class="relative">
              <input 
                type="text" 
                v-model="med.genericName" 
                placeholder="E.g. Paracetamol" 
                class="input input-bordered w-full" 
                required
              />
              <button type="button" class="btn btn-sm bg-[#C62928] text-white absolute right-2 top-2" @click="openMedicineCatalog(index)">
                <i class="las la-prescription text-xl text-white"></i>
                Open Catalog
              </button>
            </div>
            <Teleport to="body">
              <MedicineCatalog
                v-if="activeMedicineIndex !== null"
                v-model="medicineCatalogDialog"
                data-theme="easyjoey"
                hide-custom
                :generic-name="model[activeMedicineIndex].genericName"
                @select="onSelectMedicine"
              />
            </Teleport>
          </label>
          <label class="form-control col-span-2 lg:col-span-1">
            <div class="label">
              <span class="label-text font-medium">Brand Name <span class="text-error">*</span></span>
            </div>
            <input type="text" v-model="med.brandName" placeholder="E.g. Biogesic" class="input input-bordered w-full" required />
          </label>
          <label class="form-control col-span-2 lg:col-span-1">
            <div class="label">
              <span class="label-text font-medium">Route of Administration <span class="text-error">*</span></span>
            </div>
            <select v-model="med.formulationClassification" class="select select-bordered w-full" required>
              <option disabled selected value="">Select Route</option>
              <option v-for="route in ROUTE_OPTS" :key="route.value" :value="route.value">
                {{ route.text }}
              </option>
            </select>
          </label>
          <label class="form-control col-span-2 lg:col-span-1">
            <div class="label">
              <span class="label-text font-medium">Formulation</span>
            </div>
            <template v-if="med.formulations?.length > 1">
              <select v-model="med.formulation" class="select select-bordered w-full">
                <option disabled selected value="">Select Formulation</option>
                <option v-for="formulation in med.formulations" :key="formulation" :value="formulation">
                  {{ formulation.formulation }}
                </option>
              </select>
            </template>
            <template v-else>
              <input type="text" v-model="med.formulation" placeholder="e.g. 500mg tablet" class="input input-bordered w-full" />
            </template>
          </label>
          <label class="form-control col-span-2 lg:col-span-1">
            <div class="label">
              <span class="label-text font-medium">Dispense</span>
            </div>
            <input type="text" v-model="med.dispense" placeholder="e.g. 1 box" class="input input-bordered w-full" />
          </label>
          <label class="form-control col-span-2 lg:col-span-1">
            <div class="label">
              <span class="label-text font-medium">Dosage</span>
            </div>
            <input type="text" v-model="med.dosageSig" placeholder="e.g. 1 tablet" class="input input-bordered w-full" />
          </label>
          <label class="form-control col-span-2 lg:col-span-1">
            <div class="label">
              <span class="label-text font-medium">Frequency</span>
            </div>
            <select v-model="med.frequency" class="select select-bordered w-full">
              <option disabled selected value="">Select Frequency</option>
              <option v-for="frequency in FREQUENCY_OPTS" :key="frequency.value" :value="frequency.value">
                {{ frequency.text }}
              </option>
            </select>
          </label>
          <label class="form-control col-span-2">
            <div class="label">
              <span class="label-text font-medium">Refills</span>
            </div>
            <input type="text" v-model="med.frequencyReminderInterval" placeholder="No. of refills, e.g. 5" class="input input-bordered w-full" />
          </label>
          <label class="form-control col-span-2">
            <div class="label">
              <span class="label-text font-medium">Notes</span>
            </div>
            <textarea v-model="med.note" placeholder="e.g. Take with food" class="textarea textarea-bordered w-full h-24"></textarea>
          </label>
        </div>
      </template>
      <button
        type="button"
        class="btn bg-white border-gray-300 font-medium"
        @click="addMedicine"
      >
        Add Medicine
      </button>
    </div>
    <div
      v-if="!hideActions"
      class="flex gap-4 justify-end"
    >
      <button
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
      >
        Submit
      </button>
    </div>
  </form>
</template>